import { isPlatformBrowser } from '@angular/common';
import { InjectionToken, PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';

export const SCENE_URL_CONFIG = new InjectionToken<SceneUrlConfig>('SCENE_URL_CONFIG');
export interface SceneUrlConfig {
    baseUrl: string;
    adoptToDevicePixelRation?: boolean;
}

export interface UrlConfigOptions {
    width?: number;
    height?: number;
}

@Pipe({
    standalone: true,
    name: 'resolveSceneUrl'
})
export class ResolveSceneUrlPipe implements PipeTransform {
    private readonly platformId = inject(PLATFORM_ID);
    private readonly config = inject(SCENE_URL_CONFIG);

    transform(name: string | undefined | null, config: UrlConfigOptions = { width: 0, height: 0 }): string {
        let scale = 1;
        if (isPlatformBrowser(this.platformId)) {
            scale = this.config.adoptToDevicePixelRation ? window.devicePixelRatio : 1;
        }
        console.log('Scene name', name);
        if (config.width && config.height) {
            return `${this.config.baseUrl}/image/${name}/w/${config.width * scale}/h/${config.height * scale}`;
        } else if (config.width && !config.height) {
            return `${this.config.baseUrl}/image/${name}/w/${config.width * scale}/h/0`;
        } else if (!config.width && config.height) {
            return `${this.config.baseUrl}/image/${name}/w/0/h/${config.height * scale}`;
        } else {
            return `${this.config.baseUrl}/image/${name}/w/0/h/0`;
        }
    }
}
